<template>
  <div
    class="contact-us px-8 py-10 container"
    :class="{ loading: loading }"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full md:w-2/3 mb-6 md:pr-2">
        <TitleComponent
          title="Safety Management Services Contact Information"
          size="large"
        />
        <Paragraph
          ><p>
            Safety Management Services, Inc. (SMS™) is in a unique position to
            assist you. Our engineers and scientists have an average of 15 years
            of experience in explosives risk management, testing, and test
            equipment. SMS personnel have a thorough understanding of energetic
            materials in-process testing, DOT testing, risk management, and
            sensitivity test equipment. &nbsp;Please contact us at 801-567-0456
            or use the form below to request a quote or other information for
            the services we provide.
          </p>
          <p>
            Contact us to find out more about how we can support your safety
            objectives:
          </p>
        </Paragraph>

        <div class="flex flex-wrap -mx-3 mb-3">
          <div class="w-full md:w-1/2 px-3 mb-3 md:mb-0">
            <label
              class="block tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-your-name"
            >
              Your Name
            </label>
            <input
              v-model="name"
              class="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              id="grid-your-name"
              type="text"
            />
          </div>
          <div class="w-full md:w-1/2 px-3">
            <label
              class="block tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-company-name"
            >
              Company Name
            </label>
            <input
              v-model="companyname"
              class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-company-name"
              type="text"
            />
          </div>
        </div>
        <div class="flex flex-wrap -mx-3 mb-3">
          <div class="w-full md:w-1/2 px-3">
            <label
              class="blocktracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-email"
            >
              Your Contact Info (Phone and Email)
            </label>
            <input
              v-model="contact"
              class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-contact"
              type="text"
            />
          </div>
          <div class="w-full md:w-1/2 px-3 mb-3 md:mb-0">
            <label
              class="block tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-topic"
            >
              Topic
            </label>
            <div class="relative mb-3">
              <select
                v-model="topic"
                class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-topic"
              >
                <option>Facility Siting</option>
                <option>Risk Management Services</option>
                <option>Testing Services</option>
                <option>Test Equipment</option>
                <option>Training</option>
                <option>Shipping Containers</option>
              </select>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap -mx-3 mb-2">
          <div class="w-full px-3 mb-3">
            <label
              class="block tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-message"
            >
              Your Message: Please be specific
            </label>
            <textarea
              v-model="message"
              class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-message"
            >
            </textarea>
            <button
              @click="send"
              class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 mt-8 rounded"
            >
              Send
            </button>
          </div>
        </div>
      </div>
      <div class="w-full md:w-1/3 mb-6 md:pl-2">
        <div class="heading">
          <h4
            class="widget-title fusion-responsive-typography-calculated"
            data-fontsize="13"
            data-lineheight="19.5px"
            style="--fontSize:13; line-height: 1.5; --minFontSize:13;"
          >
            SMS Contact Info
          </h4>
        </div>
        <div class="contact-info-container">
          <p class="address">
            1847 West 9000 South, Suite 205<br />West Jordan, UT 84088
          </p>

          <p class="phone">Phone: 801-567-0456</p>

          <p class="fax">Fax: 801-567-0457</p>

          <p class="web">
            Web:
            <router-link class="link" to="/"> smsenergetics.com </router-link>
          </p>
        </div>
        <div class="text-left mt-8">
          <div style="display: inline-block;">
            <a
              href="https://www.facebook.com/safetymanagementservices"
              target="_blank"
              rel="noopener noreferrer"
              data-placement="top"
              data-title="facebook"
              data-toggle="tooltip"
              title=""
              data-original-title="facebook"
              class="fusion-social-network-icon fusion-tooltip fusion-facebook fusion-icon-facebook"
              ><svg
                viewBox="0 0 50 50"
                class="icon icons8-Facebook-Filled"
                style="width: 20px; fill: rgb(70, 73, 74);"
              >
                <path
                  d="M40,0H10C4.486,0,0,4.486,0,10v30c0,5.514,4.486,10,10,10h30c5.514,0,10-4.486,10-10V10C50,4.486,45.514,0,40,0z M39,17h-3 c-2.145,0-3,0.504-3,2v3h6l-1,6h-5v20h-7V28h-3v-6h3v-3c0-4.677,1.581-8,7-8c2.902,0,6,1,6,1V17z"
                ></path></svg
              ><span class="screen-reader-text">facebook</span></a
            >
          </div>
          <div style="display: inline-block; margin-left: 8px;">
            <a
              href="https://www.linkedin.com/company/safety-management-services-inc/"
              target="_blank"
              rel="noopener noreferrer"
              data-placement="top"
              data-title="linkedin"
              data-toggle="tooltip"
              title=""
              data-original-title="linkedin"
              class="fusion-social-network-icon fusion-tooltip fusion-linkedin fusion-icon-linkedin"
              ><svg viewBox="0 50 512 512" style="width: 20px;">
                <path
                  fill="#46494a"
                  d="M150.65,100.682c0,27.992-22.508,50.683-50.273,50.683c-27.765,0-50.273-22.691-50.273-50.683
                                              C50.104,72.691,72.612,50,100.377,50C128.143,50,150.65,72.691,150.65,100.682z M143.294,187.333H58.277V462h85.017V187.333z
                                              M279.195,187.333h-81.541V462h81.541c0,0,0-101.877,0-144.181c0-38.624,17.779-61.615,51.807-61.615
                                              c31.268,0,46.289,22.071,46.289,61.615c0,39.545,0,144.181,0,144.181h84.605c0,0,0-100.344,0-173.915
                                              s-41.689-109.131-99.934-109.131s-82.768,45.369-82.768,45.369V187.333z"
                ></path></svg
              ><span class="screen-reader-text">linkedin</span></a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="modal">
      <img src="@/assets/ajax-loader.gif" alt="pending" />
      <!-- Place at bottom of page -->
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Contact Us",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "Please contact SMS for all your explosives and energetic materials needs."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  },
  data: () => {
    return {
      loading: false,
      name: "",
      companyname: "",
      contact: "",
      topic: "Facility Siting",
      message: ""
    };
  },
  methods: {
    send() {
      let messageData = {
        name: this.name,
        companyname: this.companyname,
        contact: this.contact,
        topic: this.topic,
        message: this.message
      };

      let err = false;

      Object.keys(messageData).map(key => {
        if (!messageData[key].trim()) {
          err = true;
        }
      });

      if (err) {
        alert("Please populate all fields.");
        return false;
      }
      this.loading = true;

      fetch("/sendMail", {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "no-cors", // no-cors, *cors, same-origin
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(messageData) // body data type must match "Content-Type" header
      }).then(() => {
        this.loading = false;
        alert("Message Sent");
        this.topic = "";
        this.message = "";
      });
    }
  }
};
</script>

<style lang="scss">
.contact-us {
  svg {
    path {
      fill: #bebdbd !important;
    }
  }
  h4 {
    margin-top: 1.33em;
    margin-bottom: 1.33em;
    text-align: left;
    font-size: 13px;
  }
  a,
  .link {
    color: #aa222c;
  }
  .heading {
    color: #333333;
  }
  .contact-info-container {
    font-size: 13px;
    color: #747474;
    text-align: left;
  }
  .address {
    margin: 0 0 15px;
  }

  .screen-reader-text {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    width: 1px;
    word-wrap: normal !important;
  }

  .modal {
    display: none;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 0.8) 50% 50% no-repeat;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 32px;
      height: 32px;
      margin-top: -16px; /* Half the height */
      margin-left: -16px; /* Half the width */
      opacity: 0.8;
    }
  }
  &.loading {
    overflow: hidden;
  }
  &.loading .modal {
    display: block;
  }
}
</style>
